<template>
  <CBox>
    <CBox
      v-if="
        currentClubLeadersLastMonth.data &&
        currentClubLeadersLastMonth.data.length > 0 &&
        !isLoading
      "
    >
      <CText
        fontSize="18px"
        fontStyle="normal"
        fontWeight="700"
        color="#31373E"
        mb="16px"
        >Last Month's Leaders</CText
      >
      <CBox>
        <CBox
          v-for="(v, i) in currentClubLeadersLastMonth.data"
          :key="i"
          d="flex"
          alignItems="center"
          mb="2"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          rounded="md"
          p="4"
          :justifyContent="[
            'start',
            'space-between',
            'space-between',
            'space-between',
          ]"
          flexWrap="wrap"
        >
          <CBox
            d="flex"
            alignItems="center"
            :w="['100%', '40%', '40%', '40%']"
            :mb="['10px', '0', '0', '0']"
            :mr="['0', '14px', '14px', '14px']"
          >
            <CBox w="40px" :d="['none', 'block', 'block', 'block']">
              <CImage
                v-if="i == 0"
                :src="require('@/assets/icon/icon-first-mendal.svg')"
              />
              <CImage
                v-else-if="i == 1"
                :src="require('@/assets/icon/icon-second-mendal.svg')"
              />
              <CImage
                v-else-if="i == 2"
                :src="require('@/assets/icon/icon-third-mendal.svg')"
              />
              <CText
                v-else
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="fontMain"
                >#{{ i + 1 }}</CText
              >
            </CBox>
            <CAvatar
              w="35px"
              h="35px"
              mr="12px"
              :name="v.emp_full_name.split(' ')[0]"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="
                $router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })
              "
            ></CAvatar>
            <CBox>
              <CText
                color="#31373E"
                w="fit-content"
                fontSize="10px"
                fontWeight="400"
                >Devcoder</CText
              >
              <CText
                w="fit-content"
                fontSize="14px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
                >{{
                  v.emp_id === currentUser.emp_id ? "You" : v.emp_full_name
                }}</CText
              >
            </CBox>
            <CBox
              w="40px"
              :d="['flex', 'none', 'none', 'none']"
              justifyContent="end"
              ml="auto"
            >
              <CImage
                v-if="i == 0"
                :src="require('@/assets/icon/icon-first-mendal.svg')"
              />
              <CImage
                v-else-if="i == 1"
                :src="require('@/assets/icon/icon-second-mendal.svg')"
              />
              <CImage
                v-else-if="i == 2"
                :src="require('@/assets/icon/icon-third-mendal.svg')"
              />
              <CText
                v-else
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="fontMain"
                >#{{ i + 1 }}</CText
              >
            </CBox>
          </CBox>

          <CBox :w="['30%', '12%', '12%', '12%']">
            <CText fontSize="10px"> Top Language </CText>
            <CBox d="flex" alignItems="center" mt="4px">
              <CImage
                v-if="languages.includes(v.hashtag[0].name.toLowerCase())"
                :src="
                  require('@/assets/icon/icon-' +
                    v.hashtag[0].name.toLowerCase().replace('#', 's') +
                    '.svg')
                "
                :alt="v.hashtag[0].name.toLowerCase()"
                :title="v.hashtag[0].name.toLowerCase()"
                mr="8px"
                w="20px"
                d="inline-block"
              />
              <CText
                d="flex"
                :mr="['0', '40px', '40px', '40px']"
                alignItems="center"
                fontSize="14"
                textTransform="capitalize"
              >
                {{ v.hashtag[0].name ? v.hashtag[0].name : "-" }}</CText
              >
            </CBox>
          </CBox>
          <CBox mx="auto" :w="['33%', '15%', '15%', '15%']" px="8px">
            <CText fontSize="10px"> Total Durations </CText>
            <CText
              d="flex"
              alignItems="center"
              fontSize="13px"
              fontWeight="600"
            >
              {{ formatDuration(v.total_time) }}
            </CText>
          </CBox>
          <CBox :w="['24%', '12%', '12%', '12%']">
            <CText fontSize="10px"> Total Points </CText>
            <CText
              d="flex"
              alignItems="center"
              fontSize="16px"
              fontWeight="600"
            >
              <CText d="inline-block" fontSize="10px" fontWeight="400" mr="1"
                >Point</CText
              >
              {{ v.point }}
            </CText>
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox>
      <CBox
        :d="['block', 'flex', 'flex', 'flex']"
        justifyContent="space-between"
        alignItems="center"
      >
        <CText
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          color="#31373E"
          :mb="['0', '16px', '16px', '16px']"
          >{{ isThisMonth ? "This" : "Last" }} Month's Leaderboard</CText
        >
        <CBox d="flex" :mb="['16px', '2', '2', '2']">
          <CButton
            :bg="isThisMonth ? '#E1F3FF' : 'white'"
            :color="isThisMonth ? '#29AAFE' : 'black'"
            @click.native="setIsThisMonth(true)"
            :_hover="{ opacity: 0.8 }"
            :border="isThisMonth ? '#29AAFE 1px solid' : '#EFF1F5 1px solid'"
            mr="2"
            mt="2"
            fontWeight="400"
            size="0"
            fontSize="12px"
            py="4px"
            px="8px"
          >
            This Month's</CButton
          >
          <CButton
            :bg="!isThisMonth ? '#E1F3FF' : 'white'"
            :color="!isThisMonth ? '#29AAFE' : 'black'"
            @click.native="setIsThisMonth(false)"
            :_hover="{ opacity: 0.8 }"
            :border="!isThisMonth ? '#29AAFE 1px solid' : '#EFF1F5 1px solid'"
            mr="2"
            mt="2"
            fontWeight="400"
            size="0"
            fontSize="12px"
            py="4px"
            px="8px"
          >
            Last Month's</CButton
          >
        </CBox>
      </CBox>
      <CBox
        v-if="
          currentClubLeaderboards.data &&
          currentClubLeaderboards.data.length > 0 &&
          !isLoading
        "
      >
        <CBox
          v-for="(v, i) in currentClubLeaderboards.data"
          :key="i"
          d="flex"
          alignItems="center"
          mb="2"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          rounded="md"
          p="4"
          :justifyContent="[
            'start',
            'space-between',
            'space-between',
            'space-between',
          ]"
          flexWrap="wrap"
        >
          <CBox
            d="flex"
            alignItems="center"
            :w="['100%', '40%', '40%', '40%']"
            :mb="['10px', '0', '0', '0']"
            :mr="['0', '14px', '14px', '14px']"
          >
            <CBox w="40px" :d="['none', 'block', 'block', 'block']">
              <CImage
                v-if="i == 0"
                :src="require('@/assets/icon/icon-first-mendal.svg')"
              />
              <CImage
                v-else-if="i == 1"
                :src="require('@/assets/icon/icon-second-mendal.svg')"
              />
              <CImage
                v-else-if="i == 2"
                :src="require('@/assets/icon/icon-third-mendal.svg')"
              />
              <CText
                v-else
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="fontMain"
                >#{{ i + 1 }}</CText
              >
            </CBox>
            <CAvatar
              w="35px"
              h="35px"
              mr="12px"
              :name="v.emp_full_name.split(' ')[0]"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="
                $router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })
              "
            ></CAvatar>
            <CBox>
              <CText
                color="#31373E"
                w="fit-content"
                fontSize="10px"
                fontWeight="400"
                >Devcoder</CText
              >
              <CText
                w="fit-content"
                fontSize="14px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
                >{{
                  v.emp_id === currentUser.emp_id ? "You" : v.emp_full_name
                }}</CText
              >
            </CBox>
            <CBox
              w="40px"
              :d="['flex', 'none', 'none', 'none']"
              justifyContent="end"
              ml="auto"
            >
              <CImage
                v-if="i == 0"
                :src="require('@/assets/icon/icon-first-mendal.svg')"
              />
              <CImage
                v-else-if="i == 1"
                :src="require('@/assets/icon/icon-second-mendal.svg')"
              />
              <CImage
                v-else-if="i == 2"
                :src="require('@/assets/icon/icon-third-mendal.svg')"
              />
              <CText
                v-else
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="fontMain"
                >#{{ i + 1 }}</CText
              >
            </CBox>
          </CBox>

          <CBox :w="['30%', '12%', '12%', '12%']">
            <CText fontSize="10px"> Top Language </CText>
            <CBox d="flex" alignItems="center" mt="4px">
              <CImage
                v-if="languages.includes(v.hashtag[0].name.toLowerCase())"
                :src="
                  require('@/assets/icon/icon-' +
                    v.hashtag[0].name.toLowerCase().replace('#', 's') +
                    '.svg')
                "
                :alt="v.hashtag[0].name.toLowerCase()"
                :title="v.hashtag[0].name.toLowerCase()"
                mr="8px"
                w="20px"
                d="inline-block"
              />
              <CText
                d="flex"
                :mr="['0', '40px', '40px', '40px']"
                alignItems="center"
                fontSize="14"
                textTransform="capitalize"
              >
                {{ v.hashtag[0].name ? v.hashtag[0].name : "-" }}</CText
              >
            </CBox>
          </CBox>
          <CBox mx="auto" :w="['33%', '15%', '15%', '15%']" px="8px">
            <CText fontSize="10px"> Total Durations </CText>
            <CText
              d="flex"
              alignItems="center"
              fontSize="13px"
              fontWeight="600"
            >
              {{ formatDuration(v.total_time) }}
            </CText>
          </CBox>
          <CBox :w="['24%', '12%', '12%', '12%']">
            <CText fontSize="10px"> Total Points </CText>
            <CText
              d="flex"
              alignItems="center"
              fontSize="16px"
              fontWeight="600"
            >
              <CText d="inline-block" fontSize="10px" fontWeight="400" mr="1"
                >Point</CText
              >
              {{ v.point }}
            </CText>
          </CBox>
        </CBox>
      </CBox>
      <CBox v-else>
        <CText>{{
          isLoading ? "Loading..." : "No one has occupied the leaderboard yet."
        }}</CText>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CText, CImage, CAvatar } from "@chakra-ui/vue";
import {
  GET_CLUB_LEADERBOARDS,
  GET_CLUB_LEADERS_LAST_MONTH,
} from "@/store/club.module";

export default {
  name: "LeaderboardClub",
  components: {
    CBox,
    CText,
    CImage,
    CAvatar,
  },
  data() {
    return {
      isLoading: true,
      isThisMonth: true,
      languages: [
        "javascript",
        "php",
        "go",
        "python",
        "java",
        "c#",
        "rust",
        "ruby",
        "dart",
        "kotlin",
        "c",
        "c++",
        "typescript",
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser || [];
    },
    currentClubLeaderboards() {
      return this.$store.getters.currentClubLeaderboards || [];
    },
    currentClubLeadersLastMonth() {
      return this.$store.getters.currentClubLeadersLastMonth || [];
    },
  },
  async mounted() {
    this.getData();

    const today = new Date();
    const lastMonth =
      today.getFullYear() + "-" + ("0" + today.getMonth()).slice(-2);
    await this.$store.dispatch(GET_CLUB_LEADERS_LAST_MONTH, {
      slug: this.$route.params.slug,
      data: {
        group: "monthly",
        time: lastMonth,
        limit: 3,
        sortBy: "total_point",
      },
    });
  },
  methods: {
    async getData() {
      try {
        const today = new Date();
        var month = "";

        if (today.getMonth() < 9) {
          month = this.isThisMonth
            ? today.getFullYear() +
              "-" +
              ("0" + (today.getMonth() + 1)).slice(-2)
            : today.getFullYear() + "-" + ("0" + today.getMonth()).slice(-2);
        } else {
          month = this.isThisMonth
            ? today.getFullYear() + "-" + (today.getMonth() + 1).slice(-2)
            : today.getFullYear() + "-" + today.getMonth().slice(-2);
        }

        const params = {
          slug: this.$route.params.slug,
          data: {
            limit: 100,
            sortBy: "total_point",
            group: "monthly",
            time: month,
          },
        };

        await this.$store.dispatch(GET_CLUB_LEADERBOARDS, params);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async setIsThisMonth(value) {
      this.isThisMonth = value;
      await this.getData();
    },
    formatDuration(duration) {
      let arrTexts = [];
      let seconds = Math.floor(+duration / 1000);
      if (seconds > 3600) {
        const hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        arrTexts.push(`${hours}H`);
      }

      if (seconds >= 60 && seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        arrTexts.push(`${minutes}M`);
      }

      if (seconds > 0) {
        arrTexts.push(`${seconds}S`);
      }

      const formatText = arrTexts.join(" ");

      return formatText;
    },
  },
};
</script>
